import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { Link } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockContent from './block-content'
import Container from './container'
import RoleList from './role-list'

import styled from 'styled-components'
import { device } from '../styles/MediaQueries'
import { typography, Heading1, Heading3, Heading4 } from '../styles/Typography'
import { colors } from '../styles/Variables'
import RelatedSidebarItem from '../components/related-sidebar-item'

const BlogArticle = styled.article` 
  /* No styles yet */
`

const MainImage = styled.div`
  position: relative;
  background: #eee;
  padding-bottom: calc(9 / 16 * 80%);
  overflow: hidden;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: cover;
    display: block;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* grid-column-gap: 3em; */

  @media ${device.mediaMinMedium} {
    grid-template-columns: 3fr 1fr;
  }
`

const MainContent = styled.div`
  
  padding: 1rem 2rem;
  @media ${device.mediaMinMedium} {
    box-shadow: rgb(199 199 199 / 62%) -12px 0px 10px -6px inset;
  }
  & a {
    color: ${colors.colorAccent};
    
    @media (hover: hover) {
      &:hover {
        color: inherit;
      }
    }
  }
`
const MetaContent = styled.aside`
  background-color: #f6f6f7;
  padding: 2rem;
`

const PublishedAt = styled.div`
  margin: 1.5rem 0 3rem;
  /* ${typography.small} */
`
const Categories = styled.div`
  border-top: 1px solid ${colors.colorBlack};;
  margin: 2rem 0 3rem;

  & ul {
    list-style: none;
    margin: 0.75rem 0;
    padding: 0;
  }

  & ul li {
    padding: 0.25rem 0;
  }
`

const MetaHeadline = styled(Heading3)`
  margin: 0.5rem 0 1rem;  
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${colors.colorBlack}; 
`

const CategoriesHeadline = styled.h3`
  margin: 0.5rem 0 0;
  /* ${typography.base} */
`
const RelatedPosts = styled.div`

  & ul {
    list-style: none;
    padding: 0;

    li {
      &:not(:last-child) {
        border-bottom: 4px dotted #ccc;
        margin-bottom: 1rem;
      }
    }
  }

  & a {
    display: flex;
    flex-direction: column;
    color: inherit;
    text-decoration: none;
    margin-bottom: 1rem;

    img {
      margin-bottom: 0.5rem;
    }
  }
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const RelatedRecipesTitle = styled.h3`

`

const RelatedPostLink = styled(Link)`
  position: relative;
  h4 {
    /* position: absolute; 
    bottom: 1rem;
    left: 1rem;
    right: auto;*/
    /* background-color: #ffffffdd; */
    /* box-shadow: 0.2rem 0.2rem 0.25rem #00000033; */
    padding: 0.5rem 0;
    font-size: 1rem;
    margin: 0;
    /* border-radius: 1rem; */
  }
  img {
    /* border-radius: 1rem; */
  }
  &:hover {
    h4 {
      text-decoration: underline;
    }
  }
`

function BlogPost (props) {
  const { _rawBody, authors, categories, title, mainImage, publishedAt, relatedPosts } = props
  console.log('relatedPosts, ', relatedPosts.mainImage)
  return (
    <BlogArticle>
      <Container>
        {mainImage && mainImage.asset && (
          <MainImage>
            <img
              src={imageUrlFor(buildImageObj(mainImage))
                .width(1200)
                .height(Math.floor((9 / 16) * 1200))
                .fit('crop')
                .url()}
              alt={mainImage.alt}
            />
          </MainImage>
        )}

        <Grid>
          <MainContent>
            <TitleWrapper>
              <Heading1>{title}</Heading1>
              {publishedAt && (
                <PublishedAt>
                  {differenceInDays(new Date(publishedAt), new Date()) > 3
                    ? distanceInWords(new Date(publishedAt), new Date())
                    : format(new Date(publishedAt), 'MMMM Do YYYY')}
                </PublishedAt>
              )}
            </TitleWrapper>

            {_rawBody && <BlockContent blocks={_rawBody} />}
          </MainContent>
          <MetaContent>
            {/* {authors && <RoleList items={authors} title='Authors' />} */}
            {/* {categories && (
              <Categories>
                <CategoriesHeadline>Categories</CategoriesHeadline>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </Categories>
            )} */}
            {relatedPosts && (
              <RelatedPosts>
                <MetaHeadline>Related posts</MetaHeadline>
                <ul>
                  {relatedPosts.map(post => (
                    <li key={`related_${post._id}`}>
                      <RelatedPostLink to={`/blog/${format(post.publishedAt, 'YYYY/MM')}/${post.slug.current}`}>
                        {post.mainImage && post.mainImage.asset && (
                          <img
                            src={imageUrlFor(buildImageObj(post.mainImage))
                              .width(320)
                              .maxWidth(320)
                              .height(Math.floor((9 / 16) * 320))
                              .quality(100)
                              .sharpen(20)
                              .fit('crop')
                              .url()}
                            alt={post.mainImage.alt}
                          />
                        )}
                        <Heading4>{post.title}</Heading4>
                      </RelatedPostLink>
                    </li>
                  ))}
                </ul>
              </RelatedPosts>
            )}
          </MetaContent>
        </Grid>
      </Container>
    </BlogArticle>
  )
}

export default BlogPost
